.textarea-container:focus {
	border-color: #ed7d2d;
	outline: 0;
	-webkit-box-shadow: 0 0 0 1px #cc5f12ff;
	box-shadow: 0 0 0 2px #cc5f12ff;
}
.textarea-container:hover {
	border-color: #ed7d2d;
	outline: 0;
}
.textarea-container {
	/* background-color: #cccccc; */
}
