.reference-docs {
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #ddd;
    transition: all 0.2s ease;
    margin-bottom: 8px;
    font-size: 13px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.reference-docs:hover {
    background-color: #f9f9f9;
    /* color: #EC7200; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.click-paragraph {
    cursor: pointer;
    margin-bottom: 5px;
    transition: color 0.2s ease;
    color: #EC7200;
}

/* .click-paragraph:hover {
    color: #0056b3;
} */

.expanded-content {
    max-height: 300px;
    overflow-y: auto;
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 8px;
    transition: max-height 0.5s ease-out;
    margin-top: 10px;
}
