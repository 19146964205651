.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

/* width */
::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px grey;
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #87848d;
	border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #27a6a4;
}

.no-scroll-div::-webkit-scrollbar {
	display: none;
}
.no-scroll-div:hover::-webkit-scrollbar {
	display: inline-block;
}

.ant-modal-title {
	font-size: 1.5rem !important;
	font-weight: 700 !important;
}
.modal input:focus {
	border-color: #ed7d2d !important;
	outline: 0;
	-webkit-box-shadow: 0 0 0 1px #cc5f12ff !important;
	box-shadow: 0 0 0 2px #cc5f12ff !important;
}
.modal input:hover {
	border-color: #ed7d2d !important;
	outline: 0;
}
.ant-form-item-label {
	font-size: 0.8rem !important;
	font-weight: 700 !important;
}
.ant-table-body {
	overflow-y: auto !important;
}

.raw-table th {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 15vw;
}
/* .raw-table {
	max-height: 15rem;
	max-width: 50rem;
	overflow: auto;
} */
.raw-table {
	font-family: Arial, sans-serif;
	border-collapse: collapse;
	width: 100%;
	border: 1px solid #e8e8e8;
}

.raw-table th,
.raw-table td {
	border: 1px solid #e8e8e8;
	padding: 8px;
	text-align: left;
}

.raw-table th {
	background-color: #f7f7f7;
	font-weight: bold;
}

.raw-table td {
	background-color: #fff;
}

.raw-table tr:nth-child(even) {
	background-color: #f2f2f2;
}

.raw-table-striped tr:nth-child(odd) {
	background-color: #f7f7f7;
}

.raw-table-hoverable tr:hover {
	background-color: #fafafa;
}

.raw-table-bordered {
	border: 1px solid #e8e8e8;
}

.raw-table-bordered th,
.raw-table-bordered td {
	border: 1px solid #e8e8e8;
}

.raw-table-bordered th {
	background-color: #f7f7f7;
	font-weight: bold;
}

.raw-table-bordered tr:nth-child(even) {
	background-color: #f2f2f2;
}

.raw-table-bordered tr:hover {
	background-color: #fafafa;
}

.ant-upload-select {
	background: white !important;
}

.ant-collapse-content-box {
	padding: 0 !important;
}
