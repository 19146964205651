
.mycalls-section-container{
	padding: 20px;
	padding-top: 0;
}

.mycalls-section-container h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 31.92px;
    letter-spacing: 0.15px;
    color: #1A1628;
}

.seachbar-sort-filter-container{
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: space-between;
}

/* Search Bar */
.search-bar {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
	gap: 10px;
}

.search-bar .ant-input-affix-wrapper{
	border-radius: 0 ;
	padding: 0 11px;
}

.search-bar .ant-input {
    width: 300px;
    border-radius: 0;
    height: 30px;
}

.search-bar .ant-input-suffix{
	border-left: 1px solid #d9d9d9;
    padding-left: 11px;
}

.search-bar button {
    margin-left: 10px;
    background: white;
    border: 1px solid #d9d9d9;
    color: #595959;
    font-weight: 500;
	border-radius: 0;
    /* height: 40px; */
    /* border-radius: 4px; */
}

.search-bar button:hover {
    margin-left: 10px;
    background: white;
    border: 1px solid rgba(39, 166, 164, 1) !important;
    color: rgba(39, 166, 164, 1) !important;
    font-weight: 500;
	border-radius: 2px;
    /* height: 40px; */
    /* border-radius: 4px; */
}

.button-container {
	gap: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.search-bar .ant-checkbox-wrapper {
    margin-left: 20px;
    font-size: 14px;
	border-radius: 0;
}

.search-bar .ant-checkbox-inner{
	border-radius: 0;
}

/* Analyze Button Styling */
.analyze-button {
    background-color: rgba(39, 166, 164, 1);
    border-color:  rgba(39, 166, 164, 1);
    color: white;
    font-size: 16px;
    font-weight: 400;
	border-radius: 2px;
    height: 40px;
    padding: 8px 16px;
	margin-right: 0;
	margin-top: 0;
    box-shadow: rgba(0, 0, 0, 0.04);
    line-height: 24px;
    letter-spacing: -0.2px;
    display: flex;
    align-items: center;
}

.analyze-button:hover {
    background-color: rgba(39, 166, 164, 1) !important;
    border-color: rgba(39, 166, 164, 1) !important;
    color: #fff;
    transform: scale(1.01);
    align-items: center;
    display: flex;
}

/* Card Styling */

.card-top-container {
    padding: 12px;
    padding-bottom: 0;
    background-color: #FAFAFA;
}

.card-container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.card-container .ant-card .ant-card-body{
	padding: 0;
}

.card {
    border-radius: 8px;
    margin-bottom: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    background-color: white;
    padding: 0;
    cursor: pointer;
}

.card-title {
    color: rgba(39, 166, 164, 1);
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 8px;
	display: flex;
    justify-content: space-between;
    line-height: 21.79px;
}

.status-container{
    display: flex;
    align-items: center;
    gap: 6px;
}

.status-container svg {
    color: rgba(0, 170, 103, 1);
}

.status-container p {
    font-weight: 400;
    font-size: 16px;
    color: #454250;
    line-height: 24px;
}

.card-desc {
    color: #1A1628;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
    font-weight: 400;
    padding: 12px;
    padding-top: 0;
}

.card-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.reviewer-img {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    margin-right: 8px;
}

/* Status Tag Styling */
.ant-tag {
    background-color: #52c41a;
    color: white;
    font-weight: bold;
}

/* General Layout */
.ant-layout-content {
    padding: 24px;
    background-color: #f5f5f5;
}


/* Spinner Styling */
.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.hexagon-container {
    width: 52px;
    height: 52px;
    background: conic-gradient(#7232CF 0%, #EC7200 25%, #EC7200 50%, #7232CF 75%, #7232CF 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px; /* Optional: add rounding to the corners */
  }  
