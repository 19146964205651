.audio-transcript-drawer {
    .ant-drawer-title {
      font-weight: bold;
      font-size: 18px;
      margin: 5px;
      padding: 0 !important;
    }
  }

.ant-drawer-body{
    padding: 24px 0;
}
  
  .loading-spinner {
    text-align: center;
    padding: 50px;
  }
  
  .section-title {
    font-size: 14px !important;
    /* color: #3C4C58; */
    margin-bottom: 8px;
    color: #66B4EC !important;
  }

  .audio-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9; /* Light grey background */
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 10px;
  }

  .audio-container audio {
    width: 100%;
    border: none;
    outline: none;
    height: 40px;
  }

  audio::-webkit-media-controls-play-button,
audio::-webkit-media-controls-panel {
  background-color: #fff;
  padding: 0;
  margin: 0;
}
  .audio-container svg {
    color: #43BEBE;
  }

  .ant-spin-container{
    margin-top: 20px;
  }
  
  .transcript-item {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    padding: 12px 0;
    position: relative;
  }

  .transcript-container{
    display: flex;
    flex-direction: row;
  }
  
  .dot-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 12px;
    margin-top: 2px;
    top: 5px;
  }
  
  .dot {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-top: 4px;
    border: 3px solid #E06DD5;
  }
  
  .dot.dot-blue {
    border: 3px solid #66B4EC; 
  }
  
  .line {
    width: 0.6px;
    background-color: #d9d9d9; /* Grey color */
    flex-grow: 1;
    position: absolute;
    top: 10px; /* Adjusts to align with the top of the next dot */
    height: calc(100% - 6px); /* Connects the dots vertically */
    left: 50%;
    transform: translateX(-50%);    
  }

  .ant-list .ant-list-item{
    padding-top: 0 !important;
  }
  
  .speaker-time-container{
    display: flex;
    gap: 10px;
  }

  .speaker {
    font-weight: 600;
    color: #3C4C58;
    margin-bottom: 4px; /* Adjusts the space between speaker and comment */
  }
  
  .comment {
    font-size: 14px;
    color: #3C4C58;
  }
  
  .timestamp {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 12px;
    color: #8C8C8C;
  }
  
  .close-button {
    margin: 5px;
    font-size: 14px;
    font-weight: 500;
    background-color: #fff;
    border-radius: 2px;
    color: #8C8C8C;
  }

  .close-button:hover{
    margin: 5px;
    font-size: 14px;
    font-weight: 500;
    background-color: #fff;
    border-radius: 2px;
    color: #8C8C8C !important;
    border: 1px solid #8c8c8c !important;
  }
  