.containers {
	display: flex;
	height: 100vh;
}

.left,
.right {
	flex: 1;
}

.left {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.right {
	position: relative;
}
.button1 {
	color: #fff !important;
	background: #ed7d2dff !important;
}

/* Hover */
.button1:hover {
	color: #ffffffff !important; /* white */
	background: #cc5f12ff !important; /* tertiary2-600 */
}
/* Pressed */
.button1:hover:active {
	color: #ffffffff !important; /* white */
	background: #b1530fff !important; /* tertiary2-650 */
}

.overlay {
	background: #171a1f66;
	width: 100%;
	height: 100%;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
}

.overlayChild {
	border-radius: 6px;
	box-shadow: 0px 0px 1px #171a1f, 0px 0px 2px #171a1f;
	position: absolute;
	opacity: 85%;
}

.atIcon {
	background: #ed7d2dff; /* tertiary2-500 */
	border-radius: 8px; /* border-xl */
	box-shadow: 0px 0px 1px #171a1f, 0px 0px 2px #171a1f;
	top: -1.5rem;
	position: absolute;
	left: -1.5rem;
	height: 3.5rem;
	width: 3.5rem;
	justify-content: center;
	align-items: center;
	display: flex;
}
