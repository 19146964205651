.ant-breadcrumb-link {
	color: #ed7d2dff !important;
}

/* .Example__container__document {
	width: 100%;
	max-width: calc(100% - 2em);
	margin: 1em 0;
} */

.Example__container__document .react-pdf__Document {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.Example__container__document .react-pdf__Page {
	margin: 1em 0;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}

/* .Example__container__document .react-pdf__message {
	padding: 20px;
	color: white;
} */

.ant-segmented {
	background: #ed7d2dff !important;
}

.ant-segmented .ant-segmented-item {
	color: white !important;
}

.ant-segmented .ant-segmented-item-selected {
	color: #ed7d2dff !important;
}
