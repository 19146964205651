.form-header{
    display: flex !important;
    flex-direction: row;
    align-items: center!important;
    gap: 5px;
    margin-bottom: 10px;
    justify-content: space-between;
}

.form-header p {
    font-size: 15px;
    line-height: 22px;
    font-weight: 700;
}

.form-header textarea {
    padding: 4px 15px;
}

.edit-question-answer-button{
    margin-right: 10px;
    background-color: #43bebe;
    color: #fff;
    font-weight: 480;
    border: 1px solid #d9d9d9;
    border-radius: 0 !important;
    padding: 2px 7px;
    margin-bottom: 20px;
}

.question-textarea{
    margin-bottom: 10px;
}

.ant-drawer .ant-drawer-header-title{
    flex-direction: row-reverse;
    justify-content: space-between;
}

.view-change-log-button{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    padding: 5px 10px;
    color: rgba(45, 139, 147, 1);
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    background-color: #fff;
}