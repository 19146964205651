.contractTable .ant-table-thead .ant-table-cell {
	color: #ed7d2dff !important;
	background: #171a1fff !important;
	text-align: center !important;
}

.contractTable tr:nth-child(2n) td {
	background-color: #fbfbfb;
}

.ant-table-filter-trigger {
	color: white !important;
}

.ant-pagination .ant-pagination-item-active a {
	color: #ed7d2dff !important;
}

.ant-pagination .ant-pagination-item-active {
	border-color: #ed7d2dff !important;
}
.rown > th::before {
	position: "relative";
}

.ant-table-wrapper .ant-table-column-sorter-down.active {
	color: #ed7d2dff;
}

.ant-table-wrapper .ant-table-column-sorter-up.active {
	color: #ed7d2dff;
}

.ant-table-wrapper .ant-table-column-sorter {
	color: white;
}

.ant-table-wrapper .ant-table-column-sorters:hover .ant-table-column-sorter {
	color: white;
}
