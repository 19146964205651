/* QA Section CSS */
.qa-container {
  display: flex;
  padding: 20px;
  padding-top: 0;
}

/* .filter-buttons{
  display: flex;
  gap: 15px;
  margin-left: 20px;
} */

.question-assistant-header-container{
  position: sticky;
  top: 77px;
  z-index: 100; 
  background-color: #ffffff; 
  padding: 15px 0 10px;
}

.question-assistant-header {
  display: flex;
  gap: 10px;
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  letter-spacing: -0.13px;
  align-items: center;
  margin-left: 20px;
}

.question-assistant-header .active-button, .active-button:hover {
  border: 1px solid rgba(39, 166, 164, 1) !important;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.043) !important;
  background-color: rgba(39, 166, 164, 1) !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 24px !important;
  align-items: center;
  border-radius: 100px !important;
  padding: 0px 20px !important;
}

.question-assistant-header .inactive-button, .inactive-button:hover {
  border: 1px solid rgba(39, 166, 164, 1) !important;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.043) !important;
  background-color: #fff !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  color: rgba(39, 166, 164, 1) !important;
  align-items: center;
  border-radius: 100px !important;
  padding: 0px 20px !important;
}

.qa-list {
  width: 30%;
  height: 80vh; /* Adjust height as needed */
  overflow-y: auto;
  padding-right: 20px;
  position: relative; /* Allows position adjustments */
}

.qa-item {
  background: rgba(255, 255, 255, 1);
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 2px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background 0.3s ease;
  box-shadow: 2px 2px 0px 0px rgba(26, 22, 40, 0.12);
  border-radius: 4px;
  border: 1px solid rgba(222, 220, 222, 1)
}

.qa-item-selected {
  background: rgba(245, 254, 248, 1);
  border-width: 1px 1px 1px 4px;
  border-style: solid;
  border-color: rgba(49, 186, 174, 1);
  box-shadow: 4px 4px 0px 0px rgba(26, 22, 40, 0.12);
}

.qa-item-content {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  flex-direction: column;
}

.qa-item-selected .qa-question{
  color: rgba(39, 166, 164, 1);
}

.qa-question {
  font-weight: 600;
  color: rgba(135, 132, 141, 1);
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 22.4px;
  letter-spacing: -0.13px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}

.qa-header-question{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 20px;
}

.qa-header-icons{
  display: flex;
  flex-direction: row;
  gap: 0;
}

.qa-item-selected .service-now-button{
  color: rgba(39, 166, 164, 1) !important;
}

.service-now-button{
  text-align: center;
  padding: 0;
  background-color: inherit !important;
  margin: 0;
  border: 0 !important;
  color: rgba(135, 132, 141, 1) !important;
  transform: scale(1.08);
  display: flex;
  flex-direction: column;
}

.service-now-button:hover{
  text-align: center;
  padding: 0;
  background-color: inherit !important;
  margin: 0;
  border: 0 !important;
  transform: scale(1.08);
  /* color: red !important; */
}

.service-now-text{
  visibility: hidden;
    position: absolute;
    bottom: 13px;
    right: 18px;
    font-size: 12px;
    z-index: 1000;
    background-color: rgba(47, 44, 60, 1);
    padding: 3px 5px;
    color: rgba(250, 250, 250, 1);
    font-size: 10px;
    font-weight: 400;
}

.question-header-hover-text{
  visibility: hidden;
  position: absolute;
  bottom: 35px;
  right: 0px;
  font-size: 12px;
  z-index: 1000;
  background-color: rgba(47, 44, 60, 1);
  padding: 3px 5px;
  color: rgba(250, 250, 250, 1);
  font-size: 10px;
  font-weight: 400;
}

.service-now-button:hover .service-now-text, .question-header-button:hover .question-header-hover-text {
  visibility: visible;
  opacity: 1;
}



.question-header-button, .qa-edit-button{
  background-color: white;
  color: rgba(39, 166, 164, 1);
  font-weight: 1000;
  text-align: center;
}

.question-header-button{
  border-radius: 2px;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.016);
  padding: 8px;
  border: 1px solid rgba(222, 220, 222, 1);
}

.qa-edit-button:hover{
  transform: scale(1.05);
  border: 0px !important;
}

.question-header-button, .question-header-button:hover{
  margin-right: 10px;
  border-radius: 5px;
}

.question-header-button:hover, .qa-edit-button:hover {
  margin-right: 10px;
  background-color: white !important;
  color: #43BEBE !important;
  font-weight: 480 !important;
}

.question-header-button:hover{
  border: 1px solid #43BEBE !important;
}

.qa-tag {
  border: 1px solid rgba(238, 137, 42, 1) !important;
    background-color: rgba(255, 249, 243, 1) !important;
    color: rgba(186, 84, 34, 1) !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    letter-spacing: -0.18px;
  width: fit-content;
  padding: 2px 5px;
}

.qa-details {
  width: 70%;
  height: 80vh; /* Adjust height as needed */
  /* overflow-y: auto; */
}

.qa-detail-card {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
}

.qa-detail-card h2 {
  margin-top: 0;
  color: rgba(26, 22, 40, 1);
  font-weight: 700;
  font-size: 20px;
  line-height: 26.6px;
  letter-spacing: -0.34px;
}

.qa-detail-card p {
  margin-bottom: 0;
  color: #555;
}

.qa-answer {
  margin-top: 20px;
    border-left: 4px solid rgba(42, 113, 164, 1);
    background-color: rgba(248, 252, 255, 0.5);
    padding: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    gap: 15px;
}

.qa-answer-text {
  color: rgba(91, 88, 100, 1);
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.14px;
}

/* .qa-answer svg {
  margin-right: 0;
} */

.qa-answer-response {
  font-weight: 700;
}

.response-container{
  padding: 10px;
  border-left: 5px solid #3482b4;
}

.transcript-section {
  margin-top: 20px;
}

.transcript-section h5 {
  font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.13px;
    color: rgba(69, 66, 80, 1);
    margin-bottom: 20px;
}

.transcript-reference {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 10px;
  /* border-radius: 4px; */
  margin-bottom: 10px;
  /* border-left: 5px solid #3482b4; */
  gap: 25px;
}

.transcript-reference p {
  margin: 0;
  color: #333;
  font-size: 14px;
}

.speaker-comment-container{
  width: 75%;
}


.transcript-reference .speaker {
  font-size: 14px;
    font-weight: 700;
    line-height: 19.6px;
    letter-spacing: -0.13px;
    color: rgba(26, 22, 40, 1);
    margin-bottom: 5px;
}

.transcript-reference .speaker span {
  font-weight: 400;
}

.transcript-reference .time {
  font-size: 14px;
  color: rgba(135, 132, 141, 1)
}

.transcript-reference .comment {
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  letter-spacing: -0.13px;
  color: rgba(26, 22, 40, 1);
}

.view-on-transcript {
  margin-right: 10px;
  background-color: white;
  color: rgba(39, 166, 164, 1);
  text-align: center;
  align-items: center;
  border: 1px solid rgba(222, 220, 222, 1);
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.016);
    border-radius: 2px;
    padding: 0px 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
}

.view-on-transcript:hover {
  margin-right: 10px;
  background-color: white;
  border-radius: 5px;
  color: #43BEBE !important;
  text-align: center;
  border: 1px solid #43BEBE;
  align-items: center;
}

.view-on-transcript svg {
  display: inline !important;
  vertical-align: text-bottom !important;
  margin: 1px 10px 0 0 !important;
  font-weight: 1000;
}

.training-documents-section {
  margin-top: 20px;
}

.training-documents-header, .related-tickets-header{
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;
}

.training-documents-header h5 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  letter-spacing: -0.13px;
  color: rgba(69, 66, 80, 1);
}

.number-of-documents {
  background-color: rgba(39, 166, 164, 1) !important;
  border-radius: 100px;
  color: #fff !important;
  font-size: 12px;
  text-align: center;
}

.training-document-link{
  border: 1px solid #43BEBE;
  color: #43BEBE;
  padding: 4px 8px;
  background-color: rgba(245, 254, 248, 1);
    border-radius: 2px;
    border: 1px solid rgba(49, 186, 174, 1);
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
}

.training-document-link:hover{
  border: 1px solid #43BEBE;
  color: #43BEBE !important;
}

.training-documents-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.training-document-card {
  width: 48%;
  text-align: center;
}

.ant-table-cell a{
  font-weight: 500;
  color: #43BEBE;
}

.ant-table-cell button{
  color: #43BEBE;
  background-color: inherit;
}

.raise-ticket-button, .raise-ticket-button:hover{
  background-color: rgba(39, 166, 164, 1) !important;
  border-color: rgba(39, 166, 164, 1) !important;
  font-size: 16px;
  font-weight: 400;
  border-radius: 2px;
  height: 40px;
  padding: 8px 16px;
  margin-right: 0;
  margin-top: 0;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: #fff;
}