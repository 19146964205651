body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

#chat-container ol {
	list-style: auto !important;
	margin: 16px 0px !important;
	padding: 0 0 0 40px !important;
}

/* styles/tailwind.css */
@import "tailwindcss/base";
@layer base {
	h1 {
		@apply text-2xl font-bold;
	}
	h2 {
		@apply text-xl font-bold;
	}
	h3 {
		@apply text-lg font-bold;
	}
	h4 {
		@apply text-xl font-bold;
	}
	h5 {
		@apply text-base font-bold;
	}
	h6 {
		@apply text-sm font-bold;
	}
}
@import "tailwindcss/components";
@import "tailwindcss/utilities";
