/* Chatbot Container */
.chatbot-container {
	position: fixed;
	bottom: 85px;
	right: 20px;
	z-index: 200;
	width: 1000px;
	/* height: 600px; */
	background-color: #fff;
	box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
	/* height: calc(100% - 40px); */
}

/* Chatbot Header */
.chatbot-header {
	background-color: #e8e8ff;
	padding: 10px 20px;
	border-radius: 8px 8px 0 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.chatbot-header h3 {
	margin: 0;
	color: rgba(47, 44, 60, 1);
	font-size: 16px;
	line-height: 24px;
	font-weight: 700;
}

.chatbot-header .close-2 {
	font-size: 24px;
	height: 19.09;
	width: 19.09;
	cursor: pointer;
	color: rgba(47, 44, 60, 1);
}

/* Chat Container */
.chat-container {
	background-color: #ffffff;
	padding: 20px;
	border-radius: 0 0 8px 8px;
	height: 500px;
	overflow-y: auto;
	box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
}

.chatbot-top-fixed-content-plus-questions {
	margin-bottom: 50px;
}

.chatbot-top-svg-fixed-content {
	display: flex;
	flex-direction: row;
	gap: 10px;
	align-items: flex-start;
}

.questions-list {
	margin: 18px 0 20px 40px;
}

.question-item {
	border: 1px solid rgba(67, 190, 190, 1);
	border-radius: 8px;
	padding: 8px 16px;
	margin-bottom: 10px;
	color: rgba(67, 190, 190, 1);
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	width: max-content;
}

.chat-messages {
	margin-bottom: 50px;
}

.chat-message {
	margin-bottom: 30px;
}

.chat-message-content {
	display: flex;
	flex-direction: row;
	gap: 10px;
}

.chat-username-container {
	height: 32px;
	background-color: #43bebe;
	color: #fff;
	border-radius: 100px;
	font-size: 0.8rem;
	width: 32px;
	align-items: center;
	display: flex;
	justify-content: center;
}

.chat-username {
	font-size: 18px;
	font-weight: 400;
	line-height: 18px;
	color: rgba(113, 110, 121, 1);
}

.chat-message-text {
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	color: rgba(47, 44, 60, 1);
}

.chat-icon {
	width: 32px;
	height: 32px;
	transition: none;
}

.username-response-container {
	display: flex;
	flex-direction: column;
	gap: 5px;
	width: -webkit-fill-available;
}

.input-placeholder {
	/* position: fixed; */
	bottom: 85px;
	width: -webkit-fill-available;
	margin: 0;
	padding: 0;
	margin-right: 30px;
}

.chat-input-container {
	border: 1px solid rgba(204, 204, 204, 1);
	background: rgba(255, 255, 255, 1);
	margin: 10px;
	padding: 5px 10px;
	height: Fill (42px);
}

.chat-input-container:focus {
	border: 1px solid rgba(204, 204, 204, 1) !important;
}

/* User Message */
.name-response-container {
	display: flex;
	flex-direction: column;
	gap: 10px;
	align-items: flex-start;
	margin-top: 20px;
}

.user-name-container {
	color: #716e79;
	font-size: 14px;
	font-weight: 600;
}

.user-message {
	background-color: #f0f0f0;
	border-radius: 8px;
	padding: 10px 15px;
	color: #2f2c3c;
	font-size: 16px;
	line-height: 24px;
}

/* Input Box */
.input-box-div {
	background-color: #ffffff;
	padding: 10px 15px;
	border-top: 1px solid #e0e0e0;
	display: flex;
	align-items: center;
	gap: 10px;
}

.input-box-div input {
	flex: 1;
	background-color: #f8f8f8;
	border: 1px solid #e0e0e0;
	padding: 10px;
	border-radius: 4px;
	font-size: 16px;
	line-height: 24px;
}

.input-box-div button {
	background-color: #00aeef;
	color: #ffffff;
	border: none;
	padding: 10px 15px;
	border-radius: 4px;
	cursor: pointer;
}

.input-box-div button:hover {
	background-color: #008fcc;
}

/* Chatbot Toggle Button */
.chatbot-toggle-1 {
	position: fixed;
	bottom: 24px;
	right: 24px;
	display: flex;
	align-items: center;
	cursor: pointer;
	transition: opacity 0.3s ease;
	gap: 10px;
	border-radius: 100px;
}

.chatbot-toggle-1:hover {
	background: rgba(246, 241, 241, 1);
	padding-left: 10px;
}

.chatbot-toggle-1:hover .hover-text {
	opacity: 1;
}

.chatbot-toggle-1 .hover-text {
	display: inline-block;
	margin-left: 10px;
	font-size: 14px;
	opacity: 0;
	transition: opacity 0.3s ease;
}

.name-feedback-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 5px;
	width: 100%;
}

.feedback-container {
	display: flex;
	gap: 10px;
}

.feedback-button {
	background-color: white;
	color: rgba(39, 166, 164, 1);
	font-weight: 1000;
	text-align: center;
	margin-right: 0;
}

.response-sources-container {
	display: flex;
	justify-content: space-between;
	gap: 15px;
}

.sources-button {
	background-color: #fff;
	color: #43bebe;
	font-size: 15px;
}

.raise-a-issue-container {
	display: flex;
	align-items: center;
	gap: 5px;
}

/* .raise-a-issue-question-item{
    border: 1px solid rgba(67, 190, 190, 1);
    border-radius: 8px;
    padding: 8px 16px;
    margin-bottom: 10px;
    color: rgba(67, 190, 190, 1);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
} */
/* Container for the dots loader */
.loading-dots {
	display: flex;
	align-items: center;
	margin-left: 35px;
}

/* Individual dots */
.dot-1 {
	width: 11px;
	height: 11px;
	margin: 0 6px;
	border-radius: 50%;
	background: rgba(217, 217, 217, 1);
	animation: color-change 1.5s infinite step-start;
	border: 0px;
}

/* Keyframes for color-changing animation */
@keyframes color-change {
	0%,
	100% {
		background: rgba(217, 217, 217, 1);
	}
	33% {
		background: rgba(178, 176, 182, 1);
	}
	66% {
		background: rgba(217, 217, 217, 1);
	}
}

/* Add delay for each dot to create the sequential color change effect */
.dot-1:nth-child(1) {
	animation-delay: 0s;
}

.dot-1:nth-child(2) {
	animation-delay: 0.5s; /* Adjust delay for smooth transition */
}

.dot-1:nth-child(3) {
	animation-delay: 1s; /* Adjust delay for smooth transition */
}
