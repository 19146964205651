.primary {
	color: white !important;
	background: #ed7d2d !important;
	border: 1px solid #ed7d2d !important;
	border-radius: 4px;
}

/* Hover */
.primary:hover {
	background: #cc5f12ff !important; /* tertiary2-600 */

	border: 1px solid #cc5f12ff !important;
}
/* Pressed */
.primary:hover:active {
	background: #b1530fff !important; /* tertiary2-650 */
	border: 1px solid #b1530fff !important;
}

.default {
	color: #ed7d2d !important;
	background: white !important;
	border: 1px solid #ed7d2d !important;
	border-radius: 4px;
}

/* Hover */
.default:hover {
	background: #ffe0c2 !important; /* tertiary2-600 */
}
/* Pressed */
.default:hover:active {
	background: #feca98 !important; /* tertiary2-650 */
}
