/* index.css */

.email-response-content h6{
    margin-bottom: 1rem;
}
.send-email-input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    margin-bottom: 10px;
    font-size: 14px;
  }
  
  .send-email-input.error {
    border-color: red;
  }
  
  .error-text {
    color: red;
    font-size: 12px;
  }
  
  .close-action-button{
    margin-right: 10px;
    background-color: white;
    color: #d9d9d9;
    font-weight: 480;
    border: 1px solid #d9d9d9;
    border-radius: 0 !important;
  }

  .close-action-button:hover{
    margin-right: 10px;
    background-color: white !important;
    color: #43bebe !important;
    font-weight: 480;
    border: 1px solid #43bebe !important;
    border-radius: 0 !important;
  }

  .share-response-action-button{
    margin-right: 10px;
    background-color: #43bebe;
    color: #fff;
    font-weight: 480;
    border: 1px solid #d9d9d9;
    border-radius: 0 !important;
  }

  .share-response-action-button:hover{
    margin-right: 10px;
    background-color: #3fd3d3 !important;
    color: #fff !important;
    font-weight: 480;
    border: 1px solid #43bebe !important;
    border-radius: 0 !important;
  }

  .generated-response-textarea {
    width: 100%;
    padding: 20px;
    border-radius: 2px;
    overflow-y: auto;
    border: 1px solid #d9d9d9;
    font-size: 14px;
    line-height: 1.5;
    font-family: 'Arial', sans-serif;
  }
  
  .generated-response-textarea::placeholder {
    color: #bfbfbf;
  }
  