.complete-page{
  background-color: #fff;
}

.my-calls-container {
    display: flex;
  }

.audio-details-section{
  padding: 10px 20px 0;
}

.audio-details-section h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 31.92px;
  letter-spacing: -0.335999995470047px;
  color: rgba(69, 66, 80, 1);
}

.header-section {
    margin-bottom: 20px;
    /* text-align: center; */
    background-color: #fff;
    padding: 20px 0 10px;
    border-bottom: 1px solid #d9d9d9;
    position: sticky;
    top: 0;
    z-index: 100;
  }
  
  .header-section h3 {
    color: #ffffff;
    background-color: rgb(236, 114, 0, 0.9);
    font-size: 12px;
    width: fit-content;
    padding: 12px 16px;
    font-weight: 700;
    line-height: 16.32px;
    letter-spacing: 2.5px;
  }

  .content-section {
    /* margin: 20px; */
    background-color: #fff;
  }

  .action-button svg {
    display: inline !important;
    vertical-align: text-bottom !important;
    margin: 1px 10px 0 0 !important;
  }
  
  .audio-description {
    width: 65%;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.2px;
    color: rgba(69, 66, 80, 1);
  }
  .action-button {
    margin-right: 10px;
    background-color: white;
    border-radius: 2px;
    color: rgba(39, 166, 164, 1);
    border: 1px solid rgba(222, 220, 222, 1);
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.016);
  }

  .action-button span {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: rgba(39, 166, 164, 1);
  }

  .action-button-1 {
    margin-right: 10px;
    background-color: white;
    border-radius: 5px;
    color: #43BEBE;
    font-weight: 1000;
    text-align: center;
  }

  .action-button:hover {
    margin-right: 10px;
    background-color: white;
    border-radius: 2px;
    color: rgba(39, 166, 164, 1) !important;
    border: 1px solid rgba(39, 166, 164, 1) !important;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.016);
  }

  .action-button-1:hover{
    margin-right: 10px;
    background-color: white !important;
    border-radius: 5px;
    color: #43BEBE !important;
    font-weight: 1000 !important;
    border: 1px solid #43BEBE !important;
  }
  
  .action-icon-button {
    margin-right: 10px;
    color: #595959;
    background-color: white;
  }
  
  .details-section {
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    margin: 30px 20px;
  }
  
  .details-section h3 {
    margin-bottom: 15px;
    padding: 0 !important;
    color: rgba(26, 22, 40, 1);
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.12800000607967377px;
  }
  
  .details-section p {
    margin-bottom: 10px;
    color: rgba(69, 66, 80, 1);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.20000000298023224px;
  }

  .summary-attributes-container{
    color: #87848D;
    font-size: 14px;
    margin-bottom: 0 !important;
    line-height: 19.07px;
    font-weight: 400;
    display: flex;
    gap: 5px;
  }

  .summary-attributes{
    color: #87848D;
    font-size: 14px;
    line-height: 19.07px;
    font-weight: 400;
    display: flex;
    gap: 5px;
  }

  .summary-attributes p {
    color: #87848D;
    font-size: 14px;
    line-height: 19.07px;
    font-weight: 600;
  }

  .hover-text{
    color: rgba(67, 190, 190, 1) !important;
	  font-weight: 400 !important;
	  font-size: 16px !important;
	  line-height: 21.79px !important;
  }