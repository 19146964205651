.modal-custom .ant-modal-content {
    width: 800px;
    margin: auto; 
    height: 100%;
}

.modal-tabs {
    margin-top: 20px;
}

.modal-tab {
    padding: 10px;
}

.modal-tab-title {
    font-size: 18px;
    font-weight: bold;
    color: #EC7200;
    margin-bottom: 10px;
}

.modal-tab-content {
    font-size: 14px;
    line-height: 1.5;
    color: #333;
}

.context-container {
    padding: 16px;
    max-height: 400px; /* Adjust the height as needed */
    overflow-y: auto;
}

.table-container {
    max-height: 400px; /* Adjust the height as needed */
    overflow-y: auto;
    overflow-x: auto;
}

.doc-container {
    margin-bottom: 20px;
}

.doc-title {
    font-size: 16px;
    font-weight: bold;
    color: #171a1f;
    margin-bottom: 5px;
}

.doc-text {
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
    height: 200px;
    overflow-y: auto;
}

.excel-table {
    border-collapse: collapse;
    margin-top: 20px;
    min-width: max-content;
}

.excel-table th, .excel-table td {
    padding: 8px;
    border: 1px solid #ddd;
    text-align: center;
}

.excel-table th {
    background-color: #171a1f;
    color: white;
}

.excel-table td {
    background-color: #f9f9f9;
}

.no-content {
    text-align: center;
    color: #999;
    font-size: 14px;
    margin-top: 20px;
}
