.configCard .ant-card-body {
	height: 100% !important;
}

::-webkit-scrollbar {
	height: 10px !important;
}

.ant-switch.ant-switch-checked {
	background: #ed7d2dff;
}

.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
	background: #ed7d2dff;
}

button,
[type="button"] {
	background-color: rgba(0, 0, 0, 0.25);
}
