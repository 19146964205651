.dropdown-container:focus {
	border-color: #ed7d2d !important;
	outline: 0;
	-webkit-box-shadow: 0 0 0 1px #cc5f12ff;
	box-shadow: 0 0 0 2px #cc5f12ff;
}
.dropdown-container:hover {
	border-color: #ed7d2d;
	outline: 0;
}
