.key-name{
    font-weight: 600;
}

.close-button {
    margin: 5px;
    font-size: 14px;
    font-weight: 500;
    background-color: #fff;
    border-radius: 2px;
    color: #8C8C8C;
  }

  .close-button:hover{
    margin: 5px;
    font-size: 14px;
    font-weight: 500;
    background-color: #fff;
    border-radius: 2px;
    color: #8C8C8C !important;
    border: 1px solid #8c8c8c !important;
  }